import React from "react";
import "../styles/introduction.css";

const IntroductionItem = ({ icon, title, content }) => {
    return (
        <>
        <div className="startup mb-5 mt-5">
            <div>
                <img src={icon} alt="" />
            </div>
            <div className="introduction-content">
                <h5 className="startup-manufacture-finance-title">{title}</h5>
                <span>{content}</span>
            </div>
        </div>
        </>
    );
};

const IntroductionCardReusable = ({ items }) => {
    return (
        <>
        <section>
            <div className="container introduction-margin" data-aos="fade-up">
                <div className="introduction-container">                    
                        {items.map((item, index) => (
                            <div key={`${index} div`} className="introduction-left">
                            <IntroductionItem
                                key={index}
                                icon={item.icon}
                                title={item.title}
                                content={item.content}
                            />
                            </div>
                        ))}                    
                </div>
            </div>
        </section>
        </>
    );
};

export default IntroductionCardReusable;
