import ExpertiseList from "./expertise-components/expertise-list";

const Expertise = () => {
  return (
    <>
      <section className="alt-services">
        <div className="container" data-aos="fade-up">
          <div className="section-header" style={{ textAlign: "left" }}>
            <h2>Why Choose CloudAES?</h2>
          </div>

          <div className="row justify-content-between gy-4">
            <div
              className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 d-flex align-items-center"
              data-aos="fade-up"
              style={{ justifyContent: "flex-start" }}
            >
              <div className="content">
                <h2 style={{ textAlign: "left" }}>
                  Stamping DevOps as one-stop solution to attain
                  unparalleled expertise
                </h2>
              </div>
            </div>
          </div>
        </div>
        <ExpertiseList />
      </section>
    </>
  );
};

export default Expertise;
