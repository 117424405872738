import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/header";
import Home from "./components/home";
import Services from "./components/services-components/services";
//import Cloud from './components/services-components/cloud';
// import ArtificialInteglligence from './components/services-components/artificial-intelligence';
// import IndustrialAutomation from './components/services-components/industrial-automation';
// import BusinessInteglligence from './components/services-components/business-intelligence';
// import Insight from './components/insight';
// import InsightDetail from './components/child-components/insight-detail';
//import Career from "./components/career";
import Contact from "./components/contact";
import Footer from "./components/footer";
import ScrollToTop from "./components/scroll-to-top";
import HeaderService from "./components/header-service";
import HeaderContact from "./components/header-contact";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 300);
    const fetchMessage = async () => {
      await axios(`${BASE_URL}/api`, {
        method: "GET",
      }).then((res) => {
        setData(res.data.message);
      }); //.catch(() => toast.error("Message not found."))
    };
    fetchMessage();
  }, []);

  return (
    <>
      <div className="App">
        <Router>
          <ScrollToTop />
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={[<Header key="home-header" />, <Home key="home" />]} />
            <Route
              path="/service"
              element={[
                <HeaderService key="service-header" />,
                <Services key="service" />,
              ]}
            />
            {/* <Route path="/cloud" element={<Cloud />} /> 
            <Route path="/artificial-intelligence" element={<ArtificialInteglligence />} />
            <Route path="/industrial-automation" element={<IndustrialAutomation />} />
            <Route path="/business-intelligence-analytics" element={<BusinessInteglligence />} />
            <Route path="/insight" element={<Insight />} />
            <Route path="/insight-detail" element={<InsightDetail />} /> 
            <Route path="/career" element={<Career />} />  */}
            <Route
              path="/contact"
              element={[
                <HeaderContact key="contact-header" />,
                <Contact key="contact" />,
              ]}
            />
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
};

export default App;
