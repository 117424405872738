import icon from "../../assets/icons/proven-approach/1.svg";
import "../../styles/proven-approach.scss";

const icons = require.context("../../assets/icons/proven-approach", true);

const approaches = [
  {
    align: "right",
    title: "Initial Consultation",
    info: "We begin by understanding your current operations and aspirations.",
    icon: icons("./1.svg"),
  },
  {
    align: "left",
    title: "Strategic Planning",
    info: "Crafting a customized DevOps strategy that aligns with your business objectives.",
    icon: icons("./2.svg"),
  },
  {
    align: "right",
    title: "Seamless Testing",
    info: "For dev. Environment",
    icon: icons("./3.svg"),
  },
  {
    align: "left",
    title: "Seamless Implementation",
    info: "Implementing the plan with precision to ensure minimal disruption.",
    icon: icons("./4.svg"),
  },
  {
    align: "right",
    title: "Continuous Support",
    info: "Offering ongoing support and optimization to keep your operations running at peak efficiency.",
    icon: icons("./5.svg"),
  },
];

const ItemInfo = ({ title, info }) => (
  <>
    <div className="item-info">
      <h5>{title}</h5>
      <p>{info}</p>
    </div>
  </>
);
const ProvenApproach = () => {
  return (
    <>
      <section className="service-head" style={{ fontFamily: "Inter" }}>
        <div className="container">
          <div className="section-header" data-aos="fade-up">
            <h2>How We Operate</h2>
          </div>
          <div className="content" data-aos="fade-up">
            <h2>Proven Approach</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="service-proven-approach">
          {approaches.map((approach) => (
            <div className="row item" key={approach.title} data-aos="fade-up">
              <div
                className={`${
                  approach.align === "left" ? "col-6" : ""
                } col-md-5 item-left`}
              >
                {approach.align === "left" && (
                  <ItemInfo title={approach.title} info={approach.info} />
                )}
              </div>
              <div className="col-6 col-md-2 item-center">
                <div className="item-icon-wrapper">
                  <div className="item-icon">
                    <img src={approach.icon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className={`${
                  approach.align === "right" ? "col-6" : ""
                } col-md-5 item-right`}
              >
                {approach.align === "right" && (
                  <ItemInfo title={approach.title} info={approach.info} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProvenApproach;
