import { Link } from "react-router-dom";
import ServicesList from "./services-components/services-list";

const Service = ({ showExploreBtn=true }) => {
  return (
    <>
      <section
        className="alt-services"
        style={{ marginBottom: "-80px" }}
      >
        <div className="container" data-aos="fade-up">
          <div className="section-header" style={{ textAlign: "left" }}>
            <h2>What We're Offering</h2>
          </div>

          <div className="row justify-content-between gy-4">
            <div
              className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 d-flex align-items-center"
              data-aos="fade-up"
              style={{ justifyContent: "flex-start" }}
            >
              <div className="content">
                <h2 style={{ textAlign: "left" }}>
                  Services We're Providing to Our Customers
                </h2>
              </div>
            </div>

            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-lg-end"
              data-aos="fade-up"
            >
              {showExploreBtn && (
                <Link className="link-btn" to={"/service"}>
                  Explore Services
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ marginBottom: '-80px', fontFamily: 'Inter' }}>
                <div className="container" data-aos="fade-up">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th scope="row"></th>
                                <td colSpan="2"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th style={{ fontSize: '50px', color: '#d9dadc' }} scope="row">01</th>
                                <td colSpan="2" style={{ fontSize: '22px', verticalAlign: 'middle' }}>Enterprise Systems</td>
                                <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><Link to={'/enterprise-systems'} className='serviceItem'><FontAwesomeIcon icon={faCircleArrowRight} size="4x" className='faArrowRight' transform={{ rotate: 45 }} /></Link></td>
                            </tr>
                            <tr>
                                <th style={{ fontSize: '50px', color: '#d9dadc' }} scope="row">02</th>
                                <td colSpan="2" style={{ fontSize: '22px', verticalAlign: 'middle' }}>Cloud</td>
                                <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><Link to={'/cloud'} className='serviceItem'><FontAwesomeIcon icon={faCircleArrowRight} size="4x" className='faArrowRight' transform={{ rotate: 45 }} /></Link></td>
                            </tr>   
                            <tr>
                                <th style={{ fontSize: '50px', color: '#d9dadc' }} scope="row">03</th>
                                <td colSpan="2" style={{ fontSize: '22px', verticalAlign: 'middle' }}>Artificial Intelligence</td>
                                <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><Link to={'/artificial-intelligence'} className='serviceItem'><FontAwesomeIcon icon={faCircleArrowRight} size="4x" color='#E7E7E7' className='faArrowRight' transform={{ rotate: 45 }} /></Link></td>
                            </tr>                         
                            <tr>
                                <th style={{ fontSize: '50px', color: '#d9dadc' }} scope="row">04</th>
                                <td colSpan="2" style={{ fontSize: '22px', verticalAlign: 'middle' }}>Industrial Automation</td>
                                <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><Link to={'/industrial-automation'} className='serviceItem'><FontAwesomeIcon icon={faCircleArrowRight} size="4x" className='faArrowRight' transform={{ rotate: 45 }} /></Link></td>
                            </tr>                           
                            <tr>
                                <th style={{ fontSize: '50px', color: '#d9dadc' }} scope="row">05</th>
                                <td colSpan="2" style={{ fontSize: '22px', verticalAlign: 'middle' }}>Business Intelligence & Analytics</td>
                                <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><Link to={'/business-intelligence-analytics'} className='serviceItem'><FontAwesomeIcon icon={faCircleArrowRight} size="4x" color='#E7E7E7' className='faArrowRight' transform={{ rotate: 45 }} /></Link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section> */}

      <div className="container">
        <ServicesList />
      </div>
    </>
  );
};

export default Service;
