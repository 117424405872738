// import Industry from "./industry";
import Expertise from "./expertise";
import Service from "./services";
import MissionVision from "./mission-vision";
import InsightHead from "./insight-head";
import ContactHead from "./contact-head";
import Introduction from "./introduction";

const Home = () => {
    return (
        <>
            <Introduction />
            {/* <Industry />    */}
            <Expertise />
            <MissionVision />
            <Service />
            {/* <InsightHead /> */}
            <ContactHead />
        </>
    );
}

export default Home;     