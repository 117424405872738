import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import "../styles/navbar.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const logos = require.context("../assets/logos", true);

const Navigation = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const isContactActive = location.pathname === '/contact';

  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      //const nav = document.querySelector(".navbar");

      //nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      //const nav = document.querySelector(".navbar");

      //nav.classList.add("navopened");
    } else {
      //const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      //nav.classList.remove("navopened");
    }
  });
  //   function openBar() {
  //     const bar = document.querySelector(".bar");

  //     bar.classList.toggle("opened");
  //   }

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container nav-container">
          <Link to={"/"}>
            <img src={logos("./cloud-aes-inline.svg")} alt="Cloud AES logo" width="170" style={{ marginTop: '-12px' }} />
          </Link>
          <div className="menu-icon" onClick={handleShowNavbar}>
            {showNavbar ? (
              <FontAwesomeIcon icon={faXmark} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="1x" />
            )}
          </div>
          <div className={`nav-elements navbar-collapse aes-navbar-items ${showNavbar && 'active'}`}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
                className={
                  splitLocation[1] === "" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  style={{ marginRight: "1rem" }}
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li
                className={
                  splitLocation[1] === "service"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  style={{ marginRight: "1rem" }}
                  to={"/service"}
                >
                  Services
                </Link>
              </li>
              <li
                className={
                  splitLocation[1] === "contact"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className={isContactActive ? "nav-link contact-noninvert-overlap" : "nav-link contact-invert-overlap"}
                  style={{ marginRight: "1rem" }}
                  to={"/contact"}
                >
                  Contact
                </Link>
              </li>
            </ul>
            <div className="copyright">
              &copy; Copyright{" "}
              <span>&nbsp;{new Date().getFullYear()} By AESL</span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
