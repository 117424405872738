import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Navigation from "./navigation";

const banners = require.context("../assets/images/banners");
const homeImages = require.context("../assets/images/home");

const Header = () => {
  return (
    <>
      <div className="navPosition">
        <Navigation />
      </div>
      <div style={{ position: "relative" }}>
        {/* <div className='navPosition'>
                    <Navigation />
                </div> */}
        <header className="App-headers">
          <div className="row">
            <div
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 header-classX"
              style={{ padding: "0" }}
            >
              <img
                style={{ maxWidth: "100vw" }}
                src={banners("./banner-left.png")}
                className="home-banner"
                alt="banner"
              />

              <div className="headerText">
                <h1 className="container headerText1">
                  One Stop <br />
                  Solution for <br /> DevOps <br />
                  <div className="subHeaderText">Experience Seamless Efficiency, Security and Scalability</div>
                </h1>
              </div>

              <div className="card-img-overlay header-class hide-aes-circle">
                <small className="text-muted aes-circle-arrow-position">
                  <img
                    src={homeImages("./cloud-aes-circle.png")}
                    className="aes-circle"
                    alt="Cloud AES"
                  />
                </small>
              </div>
              <div className="card-img-overlay header-class hide-aes-circle-mobile">
                <small className="text-muted aes-circle-arrow-position">
                  <img
                    src={homeImages("./cloud-aes-circle-mobile.png")}
                    className="aes-circle"
                    alt="Cloud AES"
                  />
                </small>
              </div>
              <div className="card-img-overlay header-class">
                <small className="text-muted aes-circle-arrow-position">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    size="2x"
                    transform={{ rotate: 45 }}
                    className="aes-circle-arrow"
                  />
                </small>
              </div>
            </div>

            <div
              className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 hide-header-right-banners"
              style={{ padding: "0" }}
            >
              <img
                src={banners("./banner-right-one.png")}
                className="home-banner"
                alt="banner"
              />
            </div>

            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 hide-header-right-banners"
              style={{ padding: "0" }}
            >
              <img
                src={banners("./banner-right-two.png")}
                className="home-banner"
                alt="banner"
              />
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
