import React from "react";
import HeaderReusable from "./header-reusable";

const banners = require.context("../assets/images/banners");

const HeaderService = () => {
  return (
    <>
      <HeaderReusable
        title1="Explore Our"
        title2="Services"
        leftBannerUrl={banners("./banner-left.png")}
        rightBannerUrl={banners("./service-banner-right.png")}
      />
    </>
  );
};

export default HeaderService;
