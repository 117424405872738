import { useState } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ExpertiseCard = ({ bgImage, title, iconImage, description }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <>
      <div
        className="card"
        style={{ overflow: "hidden", position: "relative" }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <img src={bgImage} className="card-img-top h-100" style={{ width: "100%", height: "auto", display: "block", border: "none" }} alt="" />
        <small
          className="text-muted"
          style={{
            color: "#231f20",
            backgroundColor: "#fff",
            padding: "10px",
            position: "absolute",
            bottom: "10px",
            right: "10px",
            left: "10px",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {title}
        </small>
        <small
          className="text-muted"
          style={{
            position: "absolute",
            bottom: "40px",
            right: "20px",
          }}
        >
          <img
            src={iconImage}
            size="2x"
            style={{
              backgroundColor: "#EB1F27",
              padding: "10px",
              color: "white",
              borderRadius: "100%",
              width: "42px",
              height: "42px",
            }}
            alt=""
          />
        </small>
        {isHovering && (
          <div
            className="card-img-overlay"
            style={{
              backgroundColor: "rgba(235, 31, 39, 0.8)",
              color: "#fff",
            }}
            data-aos="slide-up"
          >
            <h4 style={{ fontSize: "16px", fontWeight: 700 }}>{title}</h4>
            <p style={{ fontWeight: 500, fontSize: "14px" }}>{description}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ExpertiseCard;
