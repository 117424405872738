import "../../styles/service-stats.scss";
const icons = require.context("../../assets/icons/service-stats", true);
const stats = [
  {
    icon: icons("./users-group.svg"),
    amount: "10+",
    title: "Clients",
  },
  {
    icon: icons("./deployed.svg"),
    amount: "20+",
    title: "Projects Deployed",
  },
];
const ServiceStats = () => {
  return (
    <>
      <section className="section-bg">
        <div className="container">
          <div className="service-stats">
            {stats.map((stat) => (
              <div className="item" key={stat.title} data-aos="fade-up">
                <img src={stat.icon} alt="" />
                <h1>{stat.amount}</h1>
                <h5>{stat.title}</h5>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceStats;
