import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const ServiceListItem = ({ item, activeService, setActiveService }) => {
  const [isItemExpanded, setItemExpanded] = useState(false);
  useEffect(() => {
    if (activeService === item.number) {
      setItemExpanded(true);
    } else {
      setItemExpanded(false);
    }
  }, [activeService, item]);
  function toggleActiveService() {
    if(activeService === item.number) {
      setActiveService('');
    } else setActiveService(item.number)
  }
  return (
    <>
      <div data-aos="fade-up" className="aesl-services-list-item">
        <div
          className={`row ${
            isItemExpanded ? "is-active" : ""
          }`}
        >
          <span className="col-12 col-md-1 d-flex align-items-center justify-content-start aesl-services-list-item-number">
            {item.number}
          </span>
          <span className="col-8 col-md-10 d-flex align-items-center aesl-services-list-item-title">
            {item.title}
          </span>
          <span className="col-4 col-md-1 d-flex align-items-center justify-content-center aesl-services-list-item-button">
            <button
              className="btn d-flex align-items-center justify-content-center"
              onClick={toggleActiveService}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                fixedWidth
                size="2x"
                flip={isItemExpanded ? "vertical" : undefined}
              />
            </button>
          </span>

          <div className={`col-12 collapse ${isItemExpanded ? "show" : ""}`}>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-11">
                <ul className="aesl-services-list-item-description">
                  {item.description.map((des) => (
                    <li key={des}>{des}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceListItem;
