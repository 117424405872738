import ContactForm from "./child-components/contact-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ToastContainer } from "react-toastify";
import "../styles/contact.css";

const contactCards = [
  {
    icon: faEnvelope,
    info: "query.aes@anwargroup.net",
  },
  {
    icon: faWhatsapp,
    info: "+8801329734520",
  },
  {
    icon: faPhone,
    info: "+8801329734520",
  },
];

const Contact = () => {
  return (
    <>
      <section className="contact-head" id="contact-head">
        <div className="container">
          <div className="section-header" data-aos="fade-up">
            <h2>Contact with CloudAES</h2>
          </div>
          <div className="content" data-aos="fade-up">
            <h2>Feel free to get in Touch with CloudAES</h2>
          </div>
        </div>
      </section>

      <section
        className="contact-form-map"
        id="contact-form-map"
        style={{ marginTop: "-100px" }}
        data-aos="fade-up"
      >
        <div className="container contact-form-wrapper section-bg">
          <div className="contact-form">
            <ContactForm />
          </div>
        </div>
      </section>

      <section
        className="contact-address"
        id="contact-address"
        style={{ marginTop: "-100px" }}
      >
        <div className="container section-bg" data-aos="fade-up">
          {contactCards.map((contact, index) => (
            <div className="address" key={contact.info + index}>
              <FontAwesomeIcon icon={contact.icon} size="3x" color="#EB1F27" />
              <p style={{ textAlign: "center", marginBottom: "0" }}>
                {contact.info}
              </p>
            </div>
          ))}
        </div>
      </section>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Contact;
