import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const submitInitialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const submitValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  phone: Yup.string().required("Phone number is required"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await axios(`${BASE_URL}/contact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: values,
    })
      .then((res) => {
        if (res.data === "success") {
          toast.success("Your message has been sent successfully. Thank you!");
        } else {
          toast.error("Something went wrong!");
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={submitInitialValues}
        validationSchema={submitValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({}) => (
          <Form>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label>Name</label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    className="form-control mt-2"
                  />
                  <div className="error">
                    <ErrorMessage name="name" component="div" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label>Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    className="form-control mt-2"
                  />
                  <div className="error">
                    <ErrorMessage name="email" component="div" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label>Phone Number</label>
                  <Field
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Enter your phone number"
                    className="form-control mt-2"
                  />
                  <div className="error">
                    <ErrorMessage name="phone" component="div" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label>Message</label>
                  <Field
                    id="message"
                    name="message"
                    type="text"
                    component="textarea"
                    placeholder="Enter your message"
                    className="form-control mt-2"
                    rows={5}
                  />
                  <div className="error">
                    <ErrorMessage name="message" component="div" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <button
                    type="submit"
                    className="w-100 form-submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
