import React, { useState, useEffect } from 'react';
import ExpertiseCard from "./expertise-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = require.context("../../assets/images/expertise", true);
const icons = require.context("../../assets/icons/expertise", true);

const expertise = [
  {
    title: "Certified Developers",
    description:
      "Our team comprises industry-certified professionals, ensuring top-tier service.",
    bgImage: images("./1.png"),
    iconImage: icons("./1.svg"),
  },
  {
    title: "Kubernetes Mastery",
    description:
      "We specialize in orchestrating containerized applications, enhancing scalability and reliability.",
    bgImage: images("./2.png"),
    iconImage: icons("./2.svg"),
  },
  {
    title: "Microservice Architecture",
    description:
      "Our expertise in microservices revolutionizes your software architecture, making it more resilient and easier to manage.",
    bgImage: images("./3.png"),
    iconImage: icons("./4.svg"),
  },
  {
    title: "Multi-Cloud Solutions",
    description:
      "We navigate complex cloud environments, optimizing your applications across different platforms.",
    bgImage: images("./4.png"),
    iconImage: icons("./4.svg"),
  },
  {
    title: "CI/CD Prowess",
    description:
      "Our continuous integration and delivery strategies speed up your development cycle without compromising quality.",
    bgImage: images("./5.png"),
    iconImage: icons("./5.svg"),
  },
  {
    title: "Advanced Code Analysis ",
    description:
      "We ensure code excellence with on-the-fly reviews and in-depth analysis.",
    bgImage: images("./6.png"),
    iconImage: icons("./6.svg"),
  },
  {
    title: "Robust Cloud Security",
    description:
      "Our security strategies protect your data across all cloud services. 24/7 Support: We're here for you around the clock, ready to assist whenever you need us.",
    bgImage: images("./7.png"),
    iconImage: icons("./7.svg"),
  },
  {
    title: "Cost-Effective Solutions",
    description:
      "Our cost optimization techniques significantly reduce your operational expenses.",
    bgImage: images("./8.png"),
    iconImage: icons("./8.svg"),
  },
  {
    title: "Comprehensive Training",
    description:
      "We empower your team with the knowledge to manage services efficiently.",
    bgImage: images("./9.png"),
    iconImage: icons("./9.svg"),
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0",
        backgroundColor: "#EB1F27",
        color: "#fff",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        right: "-40px",
      }}
      onClick={onClick}
    />
  );
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  nextArrow: <SampleNextArrow />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: undefined
      },
    },
  ],
};

const ExpertiseList = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Use useEffect to update isMobile state based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767); // Adjust the width breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <section
        className="pt-4 pb-4"
        style={{ marginBottom: "-80px", fontFamily: "Inter" }}
        data-aos="fade-up"
      >
        <div className="container">
          <div className="slider-container">
            {/* Conditional rendering based on isMobile */}
            {isMobile ? (
              // If isMobile is true, render without Slider
              expertise.map((expertise) => (
                <div className="row" key={expertise.title}>
                  <div className="col-12 mb-4">
                    <ExpertiseCard
                      title={expertise.title}
                      description={expertise.description}
                      bgImage={expertise.bgImage}
                      iconImage={expertise.iconImage}
                    />
                  </div>
                </div>
              ))
            ) : (
              // If isMobile is false, render with Slider
              <Slider {...sliderSettings}>
                {expertise.map((expertise) => (
                  <div className="row" key={expertise.title}>
                    <div className="col-12 gap-2">
                      <ExpertiseCard
                        title={expertise.title}
                        description={expertise.description}
                        bgImage={expertise.bgImage}
                        iconImage={expertise.iconImage}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpertiseList;
