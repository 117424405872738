import React from "react";
import Navigation from "./navigation";

const HeaderReusable = ({ title1, title2, leftBannerUrl, rightBannerUrl }) => {
    return (
        <>
            <div className="navPosition">
                <Navigation />
            </div>
            <div style={{ position: "relative" }}>



                <header className="App-headers">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 header-classX" style={{ padding: "0" }}>
                            <img
                                src={leftBannerUrl}
                                className="service-contact-banner"
                                style={{ maxWidth: "100%", height: "100%" }}
                                alt="banner"
                            />
                            <div className="headerText-service-contact">
                            <h1 className="container headerText-service-contact1">{title1} <br/> {title2}</h1>
                            </div>
                        </div>
                        <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 hide-header-right-banners"
                            style={{ padding: "0" }}
                        >
                            <img
                                src={rightBannerUrl}
                                className="service-contact-banner"
                                style={{ maxWidth: "100%", height: "100%" }}
                                alt="banner"
                            />
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
};

export default HeaderReusable;
