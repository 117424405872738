import { useState } from "react";
import "../../styles/services-items.css";
import ServiceListItem from "./service-list-item";

const services = [
  {
    number: "01",
    title: "Cloud Expertise",
    description: [
      "Cloud Strategy: Develop comprehensive strategies for efficient cloud utilization.",
      "Cloud Adoption: Smooth migration to cloud environments tailored to business needs.",
      "Cloud Operations: Ensure seamless and optimized cloud operations.",
      "Cloud Security: Implement robust security measures for cloud-based solutions.",
      "Cloud Accelerators: Utilize tools and technologies to accelerate cloud adoption.",
    ],
  },
  {
    number: "02",
    title: "CI/CD Implementation",
    description: [
      "Control and Faster Time-to-Market: Implement continuous integration and continuous delivery for efficient software delivery.",
      "Measurable Progress: Track and measure progress through automated pipelines.",
      "Risk Reduction: Minimize the risk of defects through automated testing in the CI/CD pipeline.",
      "Easy Maintenance and Updates: Streamline application maintenance and updates.",
    ],
  },
  {
    number: "03",
    title: "Infrastructure Automation & Orchestration",
    description: [
      "Infrastructure as Code (IaC): Enables faster provisioning and configuration with code-based infrastructure.",
      "Automate Routine Tasks: Streamline operations by automating repetitive tasks.",
      "Consistency: Ensure consistency in infrastructure and environment configurations.",
      "GitOps: Implement GitOps practices for version-controlled infrastructure management.",
    ],
  },
  {
    number: "04",
    title: "Security & Audit",
    description: [
      "Static, Dynamic, and Interactive Application Security Testing: Comprehensive security assessments.",
      "Software Composition Analysis: Analyze third-party components for vulnerabilities.",
      "Vulnerability Scanning: Regularly scan for potential security vulnerabilities.",
      "Static Code Analysis: Identify and fix security issues in the source code.",
    ],
  },
  {
    number: "05",
    title: "Monitoring & Logging",
    description: [
      "Infrastructure Monitoring: Monitor the health and performance of infrastructure components.",
      "Logging and Monitoring Systems: Design and implement robust logging and monitoring systems.",
      "Organization-Wide Policies: Enforce logging and monitoring policies across the organization.",
      "Full Visibility: Gain complete visibility into infrastructure and application components.",
      "Incident Management: Implement effective incident management through monitoring and alerting.",
    ],
  },
  {
    number: "06",
    title: "Virtualization & Containerization",
    description: [
      "Workload Reduction: Efficiently manage workloads through virtualization.",
      "Improved Hardware Utilization: Optimize hardware resources through virtualization.",
      "Portability and Flexibility: Enhance application portability and flexibility with containerization.",
      "Security and Isolation: Improve security and isolation through containerization.",
      "Resource Efficiency: Ensure efficient use of resources in virtualized and containerized environments.",
    ],
  },
];

const ServicesList = () => {
  const [activeService, setActiveService] = useState('')
  return (
    <>
      <div id="accordion" className="aesl-services-list mt-5">
        {services.map((service) => (
          <ServiceListItem item={service} activeService={activeService} setActiveService={setActiveService} key={service.number}/>
        ))}
      </div>
    </>
  );
};

export default ServicesList;
