import React, { useState } from "react";
import MissionIcon from "../assets/icons/mission-vision/mission.svg";
import VisionIcon from "../assets/icons/mission-vision/vision.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faEye } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../styles/mission-vision.css";

const ViewMoreLessList = ({ items, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedList = isExpanded ? items : items.slice(0, maxLength);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <ul>
        {truncatedList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {items.length > maxLength && (
        <button
          onClick={toggleExpansion}
          className="mission-vision-showmore-btn"
        >
          {isExpanded ? "View Less" : "View More"}
          {isExpanded ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              className="mission-vision-showmore-btn-icon-margin"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="mission-vision-showmore-btn-icon-margin"
            />
          )}
        </button>
      )}
    </>
  );
};

const ViewMoreLessText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    isExpanded || text.length <= maxLength
      ? text
      : text.substring(0, maxLength) + "...";

  return (
    <>
      <p>{truncatedText}</p>
      {text.length > maxLength && (
        <button
          onClick={toggleExpansion}
          className="mission-vision-showmore-btn"
        >
          {isExpanded ? "View Less" : "View More"}
          {isExpanded ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              className="mission-vision-showmore-btn-icon-margin"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="mission-vision-showmore-btn-icon-margin"
            />
          )}
        </button>
      )}
    </>
  );
};

const MissionVision = () => {
  const missionList = [
    "Provide seamless integration of business solutions, revolutionizing efficiency and innovation within companies.",
    "Deliver the highest level of customer service, ensuring client satisfaction at all times.",
    "Commit to the development and delivery of top-quality products with minimal lead times.",
    "Enable companies to optimize operations, becoming as lean as possible for significant cost savings.",
    "Supply comprehensive insights and intelligent solutions, driving transformative business outcomes.",
  ];
  const vissionText =
    "Transforming the landscape of business operations globally, by democratizing access to cutting-edge, intelligent, and customer-centric digital solutions.";

  return (
    <>
      <section className="section-bg mission-vision-margin">
        <div className="container">
          <div className="section-header" data-aos="fade-up">
            <h2>Our Target Statement</h2>
          </div>
          <div className="content mission-vision-subhead" data-aos="fade-up">
            <h2>Guiding Lights: Our Mission & Vision</h2>
          </div>
        </div>
      </section>

      <section className="section-bg">
        <div className="container" data-aos="fade-up">
          <div className="mission-vision-container">
            <div className="mission-vision-left">
              <div className="mission mb-5 mt-5">
                <div>
                  <img src={MissionIcon} alt="" />
                </div>
                <div>
                  <h5 className="mission-vision-title">Mission</h5>
                  <ViewMoreLessList items={missionList} maxLength={1} />
                </div>
              </div>
            </div>

            <div className="right-bar-tech"></div>

            <div className="mission-vision-right">
              <div className="vision mb-5 mt-5">
                <div>
                  <img src={VisionIcon} alt="" />
                </div>
                <div>
                  <h5 className="mission-vision-title">Vision</h5>
                  <ViewMoreLessText text={vissionText} maxLength={200} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MissionVision;
