
import React from 'react';
import HeaderReusable from './header-reusable';

const banners = require.context('../assets/images/banners')

const HeaderService = () => {
    return (
        <>
        <HeaderReusable
            title1="Reach Out for"
            title2="Services"
            leftBannerUrl={banners('./banner-left.png')}
            rightBannerUrl={banners('./contact-banner-right.png')}
        />
        </>
    );
}

export default HeaderService;