import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import AvatarImage from "../assets/images/contact/avatar.png";

const ContactHead = () => {
  return (
    <>
      <section
        className="alt-services section-bg"
        style={{
          marginTop: "80px",
          background:
            "url(../../client/build/images/contact/contact-bg.png) center no-repeat",
          backgroundPsition: "center",
          backgroundSize: "100% 100%",
          marginBottom: "80px",
        }}
      >
        <div className="container" data-aos="fade-up">
          <div
            className="section-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={AvatarImage}
              style={{
                borderRadius: "100%",
                width: "170px",
                height: "170px",
                position: "relative",
                marginRight: "-25px",
                border: "1px solid #9e9fa1",
                padding: "5px",
              }}
              alt=""
            />
            <Link to={"/contact"} className="contactForm">
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                size="10x"
                color="#EB1F27"
                className="faArrowRight"
                transform={{ rotate: 45 }}
                style={{
                  position: "relative",
                  marginLeft: "-25px",
                  backgroundColor: "#FFF",
                  borderRadius: "100%",
                }}
              />
            </Link>
          </div>
          <div className="row justify-content-between gy-4">
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center"
              data-aos="fade-up"
              style={{ justifyContent: "center" }}
            >
              <div className="content" style={{ marginTop: "40px" }}>
                <h2 style={{ textAlign: "center" }}>
                  Have any projects in mind? <br />
                  <Link
                    to={"/contact"}
                    className="contactForm"
                    style={{ color: "#EB1F27" }}
                  >
                    Get in touch
                  </Link>{" "}
                  with us!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactHead;
