import "../../styles/services.css";
import ContactHead from "../contact-head";
import Service from "../services";
import ProvenApproach from "./proven-approach";
import ServiceStats from "./service-stats";

const Services = () => {
  return (
    <>
      <Service showExploreBtn={false} />
      <ProvenApproach />
      <ServiceStats />
      <ContactHead />
    </>
  );
};

export default Services;
