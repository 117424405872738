import React from 'react';
import IntroductionCardReusable from './introduction-card-reusable';

const icons = require.context("../assets/icons/introduction");

const Introduction = () => {
    const items = [
        { icon: icons("./startup.svg"), title: "Startup", content: "Cost Savings, Faster Time-to-Market, Risk Mitigation" },
        { icon: icons("./enterprise.svg"), title: "Enterprise", content: "Cost Efficiency, Improved Efficiency and Automation, Global Collaboration" },
        { icon: icons("./bank.svg"), title: "Financial Institution", content: "Regulatory Compliance, Security Expertise, Cost Optimization" },
    ];

    return (
        <>
        <IntroductionCardReusable items={items} />       
        </>
    );
}

export default Introduction;
