import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const icons = require.context("../assets/icons/footer", true);
const logos = require.context("../assets/logos", true);

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-content footer-logo-social-links">
        <div className="container">
          <div className="footer-info">
            <img
              src={logos("./cloud-aes-primary-white.svg")}
              alt="Cloud AES logo"
            />
          </div>
          <div className="social-links footer-social-links">
            <a
              href="/"
              target="_blank"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={icons("./twitter.svg")} alt="" />
            </a>
            <a
              href="/"
              target="_blank"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={icons("./fb.svg")} alt="" />
            </a>
            <a
              href="/"
              target="_blank"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={icons("./linkedin.svg")} alt="" />
            </a>
            <a
              href="/"
              target="_blank"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={icons("./instagram.svg")} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div
        className="footer-content"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="container">
          <div
            className=""
            style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
          ></div>
        </div>
      </div>
      <div
        className="footer-content footer-menu-links"
        style={{ paddingTop: "25px" }}
      >
        <div className="container">
          <div className="footer-links footer-menu-links-left">
            <h4>Let's Start Working Together</h4>
            <h1>query.aes@anwargroup.net</h1>
          </div>
          <div className="footer-menu-links-right">
            <div className="footer-links">
              <ul>
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                {/* <li><a href="/business-bmd.html">Our Projects</a></li>
                                <li><a href="/business-financial.html">News & Media</a></li>
                                <li><a href="/business-furniture-home-decor.html">Contact</a></li> */}
              </ul>
            </div>
            <div className="footer-links">
              <ul>
                {/* <li><a href="/newsroom.html">Our FAQs</a></li> */}
                <li>
                  <Link to={"/service"}>Services</Link>
                </li>
                {/* <li><a href="/career.html">Gallery</a></li>
                                <li><a href="/contact.html">Help</a></li> */}
              </ul>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  <Link to={"/contact"}>Contact</Link>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  <Link
                    to={
                      "https://www.anwargroup.com/business-technology-aes.html"
                    }
                    style={{ color: "#EB1F27" }}
                    target="_blank"
                  >
                    AESL
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <span>&nbsp;{new Date().getFullYear()} By AESL</span>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(0)} className="gotop">
        <img src={icons("./go-up.png")} alt="" className="gotopIcon" />
      </button>
    </footer>
  );
};

export default Footer;
